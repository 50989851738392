import React, { useEffect } from "react";
import "./css/apply.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { URL1 } from "../../config";
import { Loading} from "../../loading_small"
import { Password } from "@mui/icons-material";

function PersonalDetails({setImgBlobPath,  srcImg , setsrcImg, setdata,data,Errors,setErrors , error , setError}) {
  console.log("line15");
  // console.log("lin1e16", validationError);
  const [value, setValue] = React.useState("one");
  const [load, setload] = useState(false)
  console.log(error , "FFFFFFFFFFFFFFFFFFFFFFFFF")
  const Data = useSelector((state) => state);
  console.log(Data);

  async function profilePhoto(e) {
    setload(true)
    let extension = e.target.files[0].name.slice((Math.max(0, e.target.files[0].name.lastIndexOf(".")) || Infinity) + 1)
    console.log(extension,"EXT")
    if (e.target.files[0].size > 50000){
      setError("File size must be less than 50kb")
      setload(false)
      return 
    }
    
    else if (extension != 'jpg' && extension != 'png' ){
      setError("only jpg or png file is allowed")
      setload(false)
      return 
    }

    setError("")
    const formData = new FormData();    
    formData.append("file" , e.target.files[0] )

    const res = await fetch(URL1 + "/upload_photo", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + Data.auth["access_token"],
      },
    });

    if(res.ok){
      let resJson = await res.json()
      //
      // const res3 = await fetch(URL1 + `/get_profile_url`, {
      //   method: "GET",
      //   headers: {
      //     Authorization: "Bearer " + Data.auth["access_token"],
      //   },
      // });
      // let resJson = await res3.json();
      setsrcImg(resJson.url);
      let dummy = {...data}
      dummy['pic_mime'] = resJson.obj_key
      setdata(dummy)



    }


    
    

    




    // const formData = new FormData();
    // formData.append("file", e.target.files[0]);

    // const res = await fetch(URL1 + "/upload_photo", {
    //   method: "POST",
    //   body: formData,
    //   headers: {
    //     Authorization: "Bearer " + Data.auth["access_token"],
    //   },
    // });

    // console.log(res);
    // let resJson = await res.json();

    // console.log(resJson);
    // if (resJson.message == "Uploaded") {
    //   console.log("here");
    //   const res1 = await fetch(URL1 + "/get_profile", {
    //     method: "GET",
    //     headers: {
    //       Authorization: "Bearer " + Data.auth["access_token"],
    //     },
    //   });
    //   let imgb = await res1.blob();
    //   setsrcImg(URL.createObjectURL(imgb));
    // }
    setload(false)
  }

  const checkValidation = (setErrors, data ,key) => {
    if( data.student_detail[key] =="" ){
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: "This field is required"
          }
        }
      ))
      
    }
    else if(key == 'Category'){
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: ""
          }
        }
      ))
    }
    else if (key == 'Pincode' && /^[0-9][0-9][0-9][0-9][0-9][0-9]$/.test(data.student_detail[key]) ==false  ) {
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: "Pincode can only have 6 digits"
          }
        }
      ))
    }
    else if(key == 'Name' && /^[A-Za-z\s]*$/.test(data.student_detail[key]) ==false ){
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: "name can only have alphabets and space"
          }
        }
      ))
    }
    else if (key == 'Email' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.student_detail[key]) ==false ){
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: "Please Enter valid Email"
          }
        }
      ))
    }
    else if (key == 'Phone_Number' && data.student_detail[key].length !=10) {
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: "Phone no should have 10 digits only"
          }
        }
      ))
    }


    else if (data.student_detail[key].length <3 ){
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: "It should have atleast 3 characters"
          }
        }
      ))
    }
    else{
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: ""
          }
        }
      ))
  
    }
   
  }

  const keyUp = (e) => {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.student_detail[id] = val
    
    checkValidation(setErrors, dummy, id)
  };

  function handleChange(e) {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.student_detail[id] = val
    setdata(dummy)
  }
  console.log(data)
 
  
  return (
    <div>

        <div className='row g-5 formDetails'>
              <div className='col-12 pb-3 pb-lg-0 col-lg-6 col-xl-5'> 
              {load ? <Loading/> : 
                  <div className='proAvatarUpload mb-1' style={{ backgroundImage: `url(${srcImg})` }}>
                    <button type='button' className='uploadBtn'>
                      <input type='file' onChange={profilePhoto} />
                    </button>
                  </div>}
                  <div className="mb-4"><small className="logTxtDnger">{error}</small></div>
                  {/* <div className="image">
                    <img src={srcImg} className="image1" alt="img"></img>
                    <button type="button" className="addImgBtn">
                      <div onClick={() => document.getElementById("leaf").click()}>
                        <span className="addIco"></span>
                        <p className="text-center m-0">Upload Image</p>
                        <input
                          id="leaf"
                          type={"file"}
                          hidden={true}
                          onChange={profilePhoto}
                        />
                      </div>
                    </button>
                  </div> */}

                  <div className="mb-3">
                    <label className="form-label">Name <b className="text-danger">*</b></label>
                    <input className='form-control rounded'  type="text"
                      placeholder=""                      
                      value={data["student_detail"]["Name"]}
                      id="Name"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>                      
                    <small className="logTxtDnger">{Errors.student_detail.Name}</small>
                  </div>
                  
                  <div className='mb-3'>
                    <label className="form-label">Email <b className="text-danger">*</b></label>
                    <input className='form-control rounded'  type="email"
                      placeholder=""
                      
                      value={data["student_detail"]["Email"]}
                      id="Email"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>               
                    <small className="logTxtDnger">{Errors.student_detail.Email}</small>
                  </div>                  

                  <div className='mb-3'>
                      <label className="form-label">Phone Number <b className="text-danger">*</b></label>
                      <input className='form-control rounded'  type="number"
                        placeholder=""                          
                        value={data["student_detail"]["Phone_Number"]}
                        id="Phone_Number"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>                          
                      <small className="logTxtDnger">{Errors.student_detail.Phone_Number}</small>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Date of Birth <b className="text-danger">*</b></label>
                    <input className='form-control rounded'  type="date"
                      placeholder=""
                      value={data["student_detail"]["DOB"]}
                      id="DOB"
                      max="2021-12-30"
                      min="2000-01-01"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>               
                    <small className="logTxtDnger">{Errors.student_detail.DOB}</small>
                  </div>

                  {/* <div className="mb-3">
                    <label className="form-label">Gender <b className="text-danger">*</b></label>
                    <input className='form-control rounded'  type="text"
                      placeholder=""                      
                      value={data["student_detail"]["Gender"]}
                      id="Gender"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>                      
                    <small className="logTxtDnger">{Errors.student_detail.Gender}</small>
                  </div> */}

                  <div className="mb-3">
                    <label className="form-label">Gender <b className="text-danger">*</b></label>
                    <select className="form-select" name="Gender" id="Gender" selected= {data["student_detail"]["Gender"]} onChange={(e) => {
                      handleChange(e)
                      keyUp(e)
                    } }  >
                      <option disabled selected value> -- select an option -- </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>                      
                    <small className="logTxtDnger">{Errors.student_detail.Gender}</small>
                  </div>


              </div>

              <div className='col-12 col-lg-6 col-xl-5 offset-xl-2'>                 

                  <div className="mb-3">
                    <label className="form-label">Category <b className="text-danger">*</b></label>
                    <select className="form-select" name="Category" id="Category" selected= {data["student_detail"]["Category"]} onChange={(e) => {
                      handleChange(e)
                      keyUp(e)
                    } }  >
                      <option disabled selected value> -- select an option -- </option>
                      <option value="obc">OBC</option>
                      <option value="gen">GEN</option>
                      <option value="sc">SC</option>
                      <option value="st">ST</option>
                    </select>                      
                    <small className="logTxtDnger">{Errors.student_detail.Category}</small>
                  </div>

                  <div className='mb-3'>
                      <label className="form-label">Address Line 1 <b className="text-danger">*</b></label>
                      <input className='form-control rounded'  type="text"
                        placeholder=""                        
                        value={data["student_detail"]["Addr1"]}
                        id="Addr1"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>
                        
                      <small className="logTxtDnger">{Errors.student_detail.Addr1}</small>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Address Line 2 <b className="text-danger">*</b></label>
                    <input className='form-control rounded'  type="text"
                      placeholder=""                      
                      value={data["student_detail"]["Addr2"]}
                      id="Addr2"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>                      
                    <small className="logTxtDnger">{Errors.student_detail.Addr2}</small>
                  </div>

                  <div className='mb-3'>
                      <label className="form-label">City/Town/District <b className="text-danger">*</b></label>
                      <input className='form-control rounded'  type="text"
                        placeholder=""
                        
                        value={data["student_detail"]["District"]}
                        id="District"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>                        
                      <small className="logTxtDnger">{Errors.student_detail.District}</small>
                  </div>

                  <div className='mb-3'>
                      <label className="form-label">Pincode <b className="text-danger">*</b></label>
                      <input className='form-control rounded'  type="number"
                        placeholder=""                        
                        value={data["student_detail"]["Pincode"]}
                        id="Pincode"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>                        
                      <small className="logTxtDnger">{Errors.student_detail.Pincode}</small>
                  </div>

                  {/* <div className="mb-3">
                        <label className="form-label">State <b className="text-danger">*</b></label>
                        <input className='form-control rounded'  type="text"
                          placeholder=""                          
                          value={data["student_detail"]["State"]}
                          id="State"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>                          
                        <small className="logTxtDnger">{Errors.student_detail.State}</small>
                  </div> */}

                  <div className="mb-3">
                    <label className="form-label">State <b className="text-danger">*</b></label>
                    <select className="form-select" name="State" id="State" selected= {data["student_detail"]["State"]} onChange={(e) => {
                      handleChange(e)
                      keyUp(e)
                    } }  >
                      <option disabled selected value> -- select an option -- </option>
                      <option value="Punjab">Punjab</option>
                      <option value="Chandigarh">Chandigarh</option>
                    </select>                      
                    <small className="logTxtDnger">{Errors.student_detail.State}</small>
                  </div>

                  {/* <div className="mb-3">
                      <label className="form-label">Location</label>
                      <div className='mb-4'>
                        <input className='form-control rounded'  type="text"
                          placeholder=""
                          
                          value={data["student_detail"]["Location"]}
                          id="Location"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>
                          
                        <small className="logTxtDnger">{Errors.student_detail.Location}</small>
                      </div>
                  </div> */}

                  <div className="mb-3">
                    <label className="form-label">Location <b className="text-danger">*</b></label>
                    <select className="form-select" name="Location" id="Location" selected= {data["student_detail"]["Location"]} onChange={(e) => {
                      handleChange(e)
                      keyUp(e)
                    } }  >
                      <option disabled selected value> -- select an option -- </option>
                      <option value="Rural">Rural</option>
                      <option value="Semi Urban">Semi Urban</option>
                      <option value="Urban">Urban</option>
                    </select>                      
                    <small className="logTxtDnger">{Errors.student_detail.Location}</small>
                  </div>

                  <div className="mb-3">
                        <label className="form-label">Reference By <b className="text-danger">*</b></label>
                        <input className='form-control rounded'  type="text"
                          placeholder=""                          
                          value={data["student_detail"]["Reference"]}
                          id="Reference"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>                          
                        <small className="logTxtDnger">{Errors.student_detail.Reference}</small>
                  </div>
                  

              </div>
        </div>
          
    </div>
  );
}

export default PersonalDetails;
