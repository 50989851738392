import React from 'react';
import { Link, useLocation } from "react-router-dom";
import '../css/Admission.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useSelect } from '@mui/base';
import { useDispatch, useSelector } from 'react-redux';
import { URL1 } from "../config"
import { AppliedPopup } from "./appliedPopup"
import { useState } from "react";
import { Loading } from "../loading"

function Admissions() {

  const Data = useSelector(state => state)
  const [success, setsuccess] = useState(false)
  const [loadr, setloadr] = useState(false)
  const [msg, setmsg] = useState("")
  console.log(Data, "FFFFFFFFFFFFFF")
  let dispatch = useDispatch()

  function changeform(val) {
    if (val == "10") {
      return "BJF Headstart"
    }
    else if (val == "11") {
      return "BJF Stars"
    }
    else if (val == "neet") {
      return "BJF Repeaters"
    }

  }


  async function uploadForm(e, ftt) {
    if (Data.auth.applied_for != null) {
      setsuccess(true)
      setmsg(`You have already applied for ${changeform(Data.auth.applied_for)} Program`)
    }
    else {
      let extension = e.target.files[0].name.slice((Math.max(0, e.target.files[0].name.lastIndexOf(".")) || Infinity) + 1)
      if (extension != 'pdf') {
        setsuccess(true)
        setmsg("only pdf files are alow")
        return
      }

      setloadr(true)



      try {

        let ky = `students/${Data.auth.register_id}/written_form.pdf`


        const formData = new FormData();
        formData.append("form_type", 'written')
        formData.append("Applied_For", ftt)
        formData.append("key11", ky)
        formData.append("file", e.target.files[0])

        const res = await fetch(URL1 + "/upload_form", {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Bearer " + Data.auth["access_token"],
          },
        });
        console.log(res);
        let resJson = await res.json();


        console.log(resJson);
        if (resJson.message == "successful") {
          setsuccess(true)
          setmsg("Form Uploaded Successfully")
          dispatch({ type: "APPLIED_FOR", payload: ftt })
        }
        else {
          setsuccess(true)
          setmsg("Error Occured While uploading")

        }
        setloadr(false)

      } catch (err) {
        console.log(err);
        setsuccess(true)
        setmsg("Error While Uploading Form , Please Try Again")
        setloadr(false)
      }



    }

  }

  return (

    <div className='admissions whiteTh'>

      <h1 className='headingAdmission mb-5'>Admissions | Courses</h1>
      {success ? <AppliedPopup setpopup={setsuccess} msg={msg} /> :

        <div className='container-lg vTabs'>
          <Tab.Container id="admissionsTabs" defaultActiveKey="first">
            <Row className='gx-lg-5'>
              <Col sm={6} lg={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first"><span className='ico icoEdu'></span>BJF Stars Program</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second"><span className='ico icoEdu'></span>BJF Repeaters Program</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third"><span className='ico icoEdu'></span>BJF Headstart Program</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth"><span className='ico icoEdu'></span>BJF Navodaya Mission</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fifth"><span className='ico icoEdu'></span>BJF-supported Coaching for ISTC-CSIO</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={6} lg={9}>
                <Tab.Content className='pt-4 pt-sm-0'>
                  <Tab.Pane eventKey="first">
                    <p>Bhai Jaitajee Foundation India invites applications from brilliant students of class X in Punjab for two years (classes XI and XII) free coaching to prepare them for medical and engineering college admissions!!!</p>

                    <div className='text-left pt-3'>
                      <div className='btnGroup'>
                        {true ?
                          <>
                            {loadr ? <Loading /> :
                              <button type='button' className='btn btn-primary'>Apply Now</button>}

                            <div className='hvrDD'>

                              <Link to="/admission/apply-online1" className="btn btn-primary bdrB">Apply Online</Link>
                              <button type='button' className='btn btn-primary'>
                                Upload Form <input type="file" accept="application/pdf" onChange={(e) => uploadForm(e, '11')} />
                              </button>
                            </div> </> : <Link to="/login" className="btn btn-primary bdrB">Login to Apply</Link>}
                      </div>
                      <a href='/pdf/Download-Forms-2024/BJF Stars Application Form.pdf' download="Application Form" className='btn btn-outline-secondary'>Download Form</a>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <p>Bhai Jaitajee Foundation India invites applications for BJF Repeaters Batch providing coaching for NEET to eligible students who have cleared XII.</p>
                    {/* <p><strong>Minimum eligibility criteria:</strong></p> */}
                    <div className='text-left pt-3'>
                      <div className='btnGroup'>
                        {true ? <>
                          {loadr ? <Loading /> :
                            <button type='button' className='btn btn-primary'>Apply Now</button>}

                          <div className='hvrDD'>
                            <Link to="/admission/apply-online2" className="btn btn-primary bdrB">Apply Online</Link>
                            <button type='button' className='btn btn-primary'>
                              Upload Form <input type="file" accept="application/pdf" onChange={(e) => uploadForm(e, 'neet')} />
                            </button>
                          </div> </> : <Link to="/login" className="btn btn-primary bdrB">Login to Apply</Link>}
                      </div>
                      <a href='/pdf/Download-Forms-2024/BJF Repeaters Batch Application Form.pdf' download="Application Form" className='btn btn-outline-secondary'>Download Form</a>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <p>It is an online program for the students of Classes IX & X to strengthen their Science and Mathematics.</p>
                    <p><strong>Minimum eligibility criteria:</strong></p>
                    <ul>
                      <li>Minimum 80% marks in Maths as well as Science in Class 9th for students applying for Class 10th</li>
                      <li>Minimum 80% marks in Maths as well as Science in Class 8th for students applying for Class 9th</li>
                    </ul>
                    <div className='text-left pt-3'>
                      <div className='btnGroup'>
                        {true ? <>
                          {loadr ? <Loading /> :
                            <button type='button' className='btn btn-primary'>Apply Now</button>}

                          <div className='hvrDD'>
                            <Link to="/admission/apply-online" className="btn btn-primary bdrB">Apply Online</Link>
                            <button type='button' className='btn btn-primary' >
                              Upload Form <input type="file" accept="application/pdf" onChange={(e) => uploadForm(e, '10')} />
                            </button>
                          </div> </> : <Link to="/login" className="btn btn-primary bdrB">Login to Apply</Link>}
                      </div>
                      <a href='/pdf/Download-Forms-2024/BJF Headstart Application Form.pdf' download="Application Form" className='btn btn-outline-secondary'>Download Form</a>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <p>Under this program class 5 students of rural areas are given free coaching on Sundays all holidays for four months, for the entrance test to join Navodaya Vidyalayas in class 6.</p>
                    <a href='/pdf/app-forms/v2/BJF-Navodaya-Application-Form.pdf' download="Application Form" className='btn btn-outline-secondary'>Download Form</a>
                    <p className='mt-4'><strong>To apply for this program visit the campus onsite. </strong></p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <p><strong>BJF-supported Coaching for ISTC-CSIO</strong></p>
                    <p>Deserving students are supported for a 40-days extensive coaching program for preparation of ISTC-CSIO
                      entrance exam for admission to the Diploma courses offered by Indo-Swiss Training Centre (ISTC) of
                      Central Scientific Instruments Organization (CSIO).</p>
                    <p>21 students have been supported for this program to date by BJFI out of which the students who have
                      completed the course are already placed in various reputed organizations.</p>
                      <a href='/pdf/Download-Forms-2024/Application Form_BJF supported ISTC-CSIO Entrance Preparation.pdf' download="Application Form" className='btn btn-outline-secondary'>Download Form</a>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>}
    </div>
  )
}

export default Admissions