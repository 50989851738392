import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import YouTube, { YouTubePlayer } from "react-youtube";
import "../css/Hereit.css";

import noImgBG from '../images/testimonial/noImage.jpg';
import testBg0 from '../images/testimonial/Manisha-Garg-MBBS.png';
import testBg1 from '../images/testimonial/Shashi-Kumar-IIT-Roorkee.png';
import testBg2 from '../images/testimonial/Bikram-Singh-Father.png';
import testBg3 from '../images/testimonial/Dr-Lovepreet-Singh.png';
import testBg4 from '../images/testimonial/Dr-Aarti-MBBS.png';
import testBg5 from '../images/testimonial/shivam-dangwal.jpg';


// Ref: https://codesandbox.io/s/react-youtube-play-pause-video-using-an-external-button-c77o1v?file=/src/App.tsx:2651-2695

const HereIt = () => {

    const [isActive, setIsActive] = useState({
        id: '',
    })
    useEffect(() => {
        console.log(isActive)
    }, [isActive])
    const hideShowDiv = (e) => {
        setIsActive({
            id: e.target.id,
        })

       // setIsPaused(!isPaused);
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
		adaptiveHeight: true
    };

    const [isPaused, setIsPaused] = useState(false);    

    const opts = {
        height: "390",
        width: "640",
        playerVars: {
            autoplay: 0
        }
    };

    const [videoElement, setvideoElement] = useState(null)

    const ids = [ {'id' : 'divOne' , 'key' : "nwR1-p0a-Vs" } ,{'id' : 'divTwo' , 'key' : "C6TNHn3A32I" } ,{'id' : 'divThree' , 'key' : "5T1V6ul0vQU" } ,{'id' : 'divFour' , 'key' : "nbuK-bmSvPc" } ,{'id' : 'divFive' , 'key' : "MKZJ67mnRxc"  } ]

    

    const _onReady = (event) => {
        setvideoElement(event.target)
    };
    const togglePause = () => {
        videoElement.stopVideo();
        setvideoElement(null)
    };

    console.log()
    

    return (
        <>
            <div className="here-it-section">
                <h1 className="text-center mb-4 text-primary">Hear it from Students</h1>
                <div className="container-xl">
                    <div className="testimonyWrap">
                        <Slider {...settings}>
                            {/* <div>
                                <div className="row">
                                    <div className="col-12 col-md-8 col-xl-9 p-2">
                                        <div class="whiteBox">
                                            <div className="scrollCont">Ms. Manisha Garg from Jodhpur (mother of a BJF India graduate) shares her deepest gratitude for the exceptional services provided by BJF India.</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3 p-2">
                                        <div className="mediaBox d-flex align-items-end p-0" style={{ backgroundImage: `url(${testBg0})` }}>
                                            <div className="d-flex align-items-end w-100 justify-content-between p-3">
                                                <div>
                                                    <h6>Manisha Garg, MBBS (Parent)</h6>
                                                    <p className="m-0">BJFI Student - 2019</p>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn btnPlay rounded-0"
                                                        id="divOne"
                                                        onClick={(e) => {
                                                            hideShowDiv(e)
                                                        }}
                                                    >
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div>
                                <div className="row">
                                    <div className="col-12 col-md-8 col-xl-9 p-2">
                                        <div class="whiteBox">
                                            <div className="scrollCont">Shashi Kumar from IIT Roorkee shares his wonderful journey at BJF India. He found the institutes' environment to be caring and family like. He is grateful for the opportunities and guidance received from BJF India and is confident that the caring ethos of the institute will continue to positively impact lives of future learners. </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3 p-2">
                                        <div className="mediaBox d-flex align-items-end p-0" style={{ backgroundImage: `url(${testBg1})` }}>
                                            <div className="d-flex align-items-end w-100 justify-content-between p-3">
                                                <div>
                                                    <h6>Shashi Kumar IIT Roorkee (Mech.)</h6>
                                                    <p className="m-0">BJFI Student - 2015</p>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn btnPlay rounded-0"
                                                        id="divTwo"
                                                        onClick={(e) => {
                                                            hideShowDiv(e)
                                                        }}
                                                    >
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-md-8 col-xl-9 p-2">
                                        <div class="whiteBox">
                                            <div className="scrollCont">Father of Mr. Bikram Singh (a BJF India graduate) expresses his thankfulness to BJF India. His son completed his high school studies from BJF India, post which he got admission at IIIT Allahabad. Completing his graduation, he got placed in a very good company at Banglore. Mr. Bikram's father hopes that BJF India continues with its excellence and help more underpriveleged families.</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3 p-2">
                                        <div className="mediaBox d-flex align-items-end p-0" style={{ backgroundImage: `url(${testBg2})` }}>
                                            <div className="d-flex align-items-end w-100 justify-content-between p-3">
                                                <div>
                                                    <h6>Bikram Singh (Parent)</h6>
                                                    <p className="m-0">BJFI Student</p>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn btnPlay rounded-0"
                                                        id="divThree"
                                                        onClick={(e) => {
                                                            hideShowDiv(e)
                                                        }}
                                                    >
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-md-8 col-xl-9 p-2">
                                        <div class="whiteBox">
                                            <div className="scrollCont">Mr. Lovepreet joined BJF India in 2015. He expresses his appreciation for BJF India's incredible services that not only nurtured his academic growth but also played a signifacnt role in his personal and professional development.</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3 p-2">
                                        <div className="mediaBox d-flex align-items-end p-0" style={{ backgroundImage: `url(${testBg3})` }}>
                                            <div className="d-flex align-items-end w-100 justify-content-between p-3">
                                                <div>
                                                    <h6>Dr. Lovepreet Singh</h6>
                                                    <p className="m-0">BJFI Student - 2015</p>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn btnPlay rounded-0"
                                                        id="divFour"
                                                        onClick={(e) => {
                                                            hideShowDiv(e)
                                                        }}
                                                    >
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-md-8 col-xl-9 p-2">
                                        <div class="whiteBox">
                                            <div className="scrollCont">Dr. Aarti (MBBS) a student of Government Medical College shares her experience at BJF India. She is grateful to BJF for not only the assistance provided in academic growth but also support for growth in her spritual development.</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3 p-2">
                                        <div className="mediaBox d-flex align-items-end p-0" style={{ backgroundImage: `url(${testBg4})` }}>
                                            <div className="d-flex align-items-end w-100 justify-content-between p-3">
                                                <div>
                                                    <h6>Dr. Aarti (MBBS)</h6>
                                                    <p className="m-0">BJFI Student - 2015</p>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn btnPlay rounded-0"
                                                        id="divFive"
                                                        onClick={(e) => {
                                                            hideShowDiv(e)
                                                        }}
                                                    >
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-md-8 col-xl-9 p-2">
                                        <div class="whiteBox">
                                            <div className="scrollCont">
                                                <p>I'm writing this letter to let you know that I've recently started working at Capgemini as a senior analyst and will be moving to Bangaluru on 8th of December to join the office.</p>
                                                <p>I just wanted to take a moment to thank you for supporting me and helping me throughout my journey. Without your support &amp; guidance I would not have been where I'm today.</p>
                                                <p>You've always been there for me and backed me and it means alot to me. I still remember the time when you gave me pocket money, and said I am like your father. I'll always be humbled by this heartwarming gesture of yours. I've always been sufficed by your support as a father.</p>
                                                <p>I'm really grateful to you and the entire staff at BJF. The time spent there will always be memorable to me.</p>
                                                <p>Once again, thank you for everything you've done for all your other students and me. The impact you've had and continue to have cannot be overstated.</p>
                                                <p>I'll be remembering your good deeds and will pray to God for your good health.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3 p-2">
                                        <div className="mediaBox d-flex align-items-end noImg p-0" style={{ backgroundImage: `url(${noImgBG})` }}>
                                            <div className="d-flex align-items-end w-100 justify-content-between p-3">
                                                <div>
                                                    <h6>Kajal</h6>
                                                    <p className="m-0">BJFI Student - 2018</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-12 col-md-8 col-xl-9 p-2">
                                        <div class="whiteBox">
                                            <div className="scrollCont">
                                                <p>Shivam Dangwal, BJF Stars 2016 alumnus, who joined PEC Chandigarh (Mechanical Engineering) in 2016, worked with McDermott & is now joining MBA in Industrial Engineering at National Institute of Industrial Engineering, Mumbai, visited BJF, interacted with the students of BJF Stars 2023 & gave them the following advice:</p>
                                                <ol>
                                                    <li>In most schools nowadays, C and C++ languages are optional subjects in the curriculum. I feel that 60% students have prior knowledge of coding. As BJF students have no previous introduction to programming languages, you should get familiar with coding basics. YouTube channel "Code with Harry" is a good channel.</li>
                                                    <li>Communication skills, especially in English, are important.  Learn basic grammar rules like tenses, active passive, narration etc. without going too deep. I learned English by watching movies, news, & web series, & tried to imitate the way of speaking, putting my ideas in front of people etc. I suggest the "Learn English with TV" YouTube channel & Friends web series (it is fun, yet informative).</li>
                                                    <li>Make connections and try to learn from the people. Grow your healthy connections stronger and try to break ties or distance yourself from bad connections. As they say, "we are an average of 5 people with whom with we mostly spend our time", surround yourselves with uplifting people so they act like tailwinds, not headwinds.</li>
                                                    <li>Solve problems for which you have to step out of your comfort zone. If the problems are way easier than your level, you'll lose fun and interest, such as Sachin playing cricket with a 10-year-old kid. If the problem is too difficult, you will lose interest. If the problem is one step below your skill level, you will enjoy beating it but somehow, your skills won't improve. So, choose problems that are one step above your level & upskill yourself.<br />(1.1)^365= 37.8 and (0.99)^365=0.0255   This is the power of growing one step and compounding it.</li>
                                                    <li>Find your passion or interest so you get up excited and energetic every morning.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3 p-2">
                                        <div className="mediaBox d-flex align-items-end p-0" style={{ backgroundImage: `url(${testBg5})` }}>
                                            <div className="d-flex align-items-end w-100 justify-content-between p-3">
                                                <div>
                                                    <h6>Shivam Dangwal</h6>
                                                    <p className="m-0">BJF Stars 2016</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>

            {ids.map(itm => {
                return (
                    
                    <div className={isActive.id === itm.id ? `divOne popOuter indv` : 'divOne d-none popOuter indv'}>
                        <div className='popIn'>
                            <button className='closeBtn' title='Close' id="divOne" onClick={(e) => { hideShowDiv(e) }}><HighlightOffIcon /></button>
                            <div className='popInCont'>
                                {/* <YoutubeEmbed embedId="nwR1-p0a-Vs" /> */}
                                {isActive.id == itm.id ? 
                                <div className="ratio ratio-16x9">
                                    <YouTube videoId={itm.key} opts={opts} onReady={_onReady} />                            
                                </div> : null }
                                {/* <button onClick={togglePause}>Pause</button> */}
                            </div>
                        </div>
                    </div>

                )
            })}


            
        </>
    )


}

export default HereIt;